import { defineStore } from 'pinia'
import {
  IAlert,
  IApiCallEntry,
  ICategory, IClient, IClientList,
  IGuest,
  IPushData, IRealtimeUsersData,
  IUser
} from '@/api/types';
import { AlertFlavor } from '@/api/enums';
import configs from '../configs'
import _, { isArray } from 'lodash';
import {
  localFeatures,
  timeouts
} from '@/utils/constants';
import Vuetify from '@/plugins/vuetify';
import Vue from 'vue';
import useAuctionStore from '@/stores/auctionStore';
import SocketClient from '@/utils/socket';
import * as Sentry from '@sentry/vue';
import router from '@/router';
import { i18n } from '@/main';
import moment from 'moment/moment';
import {
  checkIfTokenIsValid,
  getAuthToken,
  getConnectedUser,
  removeAuthToken
} from '@/utils/token';
import dispatcher from '@/api/dispatch';
import { TabIdCoordinator } from 'browser-tab-id';
import { SocketConnectionStatus, SocketDisconnectReason, ThemeType } from '@/api/enums';
import getBrowserFingerprint from 'get-browser-fingerprint';
import httpClient from '@/utils/httpClient';

interface IRootStoreState {
  socket: Record<string, any>,
  appLoading: boolean,
  apiLoading: IApiCallEntry[],
  userProfile: IUser,
  token: string,
  error: boolean,
  errorMessage: string,
  notificationList: any[],
  showBottomNavigation: boolean,
  showEditProfileModal: boolean,
  showKnockdownsModal: boolean,
  showProductCatalogue: boolean,
  productCataloguePredefinedValue: boolean,
  showAuctionOverview: boolean,
  showAuctionModal: boolean,
  showAdminKnockdownsModal: boolean,
  appSettings: Record<string, any>,
  streamSettings: Record<string, any>,
  appLocalization: Record<string, any>,
  appFeatures: Record<string, any>
  clientList: IClient[],
  guestList: IGuest[],
  realtimeClientData: IRealtimeUsersData,
  appStoreLang: string,
  showArticlesList: boolean,
  cookiesStatus: boolean,
  isMobile: boolean,
  isIframe: boolean,
  loginButtonDisabled: boolean,
  categories: ICategory[],
  alert: IAlert[],
  sendMessageModal: boolean,
  disconnectedTime: number,
  adminDataChange: IPushData,
  users: IUser[],
  adminCategories: ICategory[],
  isOffline: boolean
  isLogoutClicked: boolean,
  globalTheme: ThemeType,
  unexpectedError: string,
  adminDrawer: boolean,
  socketConnectionStatus: SocketConnectionStatus,
  socketDisconnectReason: SocketDisconnectReason,
  preselectionData: IPreselectionData,
  showTfaDialog: boolean,
  browserTabId: string,
  lastUpdatedAdminCategories: Date,
  lastUpdatedUsers: Date,
  browserFingerprint: string
}

interface IPreselectionData {
  selectedAuctionId: number // selected auction id through the auction selector for i.e. product catalogue
}

const useRootStore = defineStore('rootStore', {
  state (): IRootStoreState {
    return {
      socket: {
        customConnectionStatus: 'init'
      },
      appLoading: false, // flag to control if the global loading overlay should be displayed (display if false)
      apiLoading: [], // currently ongoing api calls
      userProfile: {} as IUser, // Current user profile
      token: '', // jwt (not used, can be removed)
      error: false,
      errorMessage: '',
      notificationList: [], // notifications (not used)
      showBottomNavigation: true, // Show bottom mobile notification bar
      showEditProfileModal: false, // Show user edit profile modal
      showKnockdownsModal: false, // Show user knockdowns profile modal
      showProductCatalogue: false, // Show user product catalogue
      productCataloguePredefinedValue: false,
      showAuctionOverview: true, // Show user auction dashboard
      showAuctionModal: false, // Show user knockdowns profile modal
      showAdminKnockdownsModal: false,
      appSettings: {}, // general app settings
      streamSettings: {}, // streaming settings
      appLocalization: {}, // localization settings
      appFeatures: {}, // app features
      clientList: [], // socket client list
      guestList: [], // socket guest list
      realtimeClientData: null, // socket client data
      appStoreLang: '', // the current ui language
      showArticlesList: true, // flag to control if the article slider should be shown on the auction view for users and guests
      cookiesStatus: window.localStorage.getItem('vue-cookie-accept-decline-cookies') === 'accept', // if cookies are accepted by the user
      isMobile: window.innerWidth <= 968, // Flag which is set for mobile devices
      isIframe: window !== window.parent, // Flag which is set if the frontend runs in an iframe
      loginButtonDisabled: false, // Flag which disables the login button, used when restoring the config or db as the backend needs to restart
      categories: [], // fetched categories for the user frontend
      alert: [], // array of messages (success, error or messages sent by the admin)e either set by SET_ALERT or by SET_TEMP_ALERT
      sendMessageModal: false, // flag to control if the modal to send messages to users should be shown
      disconnectedTime: 0, // socket disconnected time
      adminDataChange: null, // payload which is received via the websocket data push, triggers updates or the icon in the admin fe
      users: [], // fetched users for the admin frontend
      adminCategories: [], // fetched categories for the user frontend
      isOffline: false, // app offline status (socket), can take up to 15s to detect if no internet
      isLogoutClicked: false, // Flag which is set during logout (if the user clicked the button)
      globalTheme: configs.theme.globalTheme,
      unexpectedError: '', // unexpected error, triggers the fault page
      adminDrawer: true, // flag to control if the admin menu should be shown
      socketConnectionStatus: SocketConnectionStatus.init, // socket connection status
      socketDisconnectReason: SocketDisconnectReason.notDisconnected, // socket disconnect reason (see https://socket.io/docs/v4/client-api/#event-disconnect)
      // that the user is already joined to the room
      preselectionData: {selectedAuctionId: null}, // selected auction id through the auction selector for i.e. product catalogue
      showTfaDialog: false, // flag to control if the tfa dialog should be shown
      browserTabId: null, // browser tab identification
      lastUpdatedAdminCategories: null, // the last time the categories have been updated for the admin frontend
      lastUpdatedUsers: null, // the last time the users have been updated for the admin frontend
      browserFingerprint: null // the device (browser) fingerprint
    }
  },
  getters: {
    isAuthenticatedAsUser (state: any): boolean {
      return !_.isEmpty(state.userProfile)
    }
  },
  actions: {
    UPDATE_GLOBAL_STATE(object: any): void {
      if (isArray(object) && object.length > 0) {
        object.forEach((item: any) => {
          this[item.key] = item.value
        })
      } else {
        this[object.key] = object.value
      }
    },
    APP_GLOBAL_STORE_LANG (lang: string): void {
      this.appStoreLang = lang
    },
    SET_IS_MOBILE (): void {
      this.isMobile = window.innerWidth <= 968
    },
    SET_IS_IFRAME (): void {
      this.isIframe = (window !== window.parent)
    },
    SET_LOGIN_BUTTON_DISABLED (data: boolean): void {
      this.loginButtonDisabled = data
    },
    SET_CATEGORIES (categories: ICategory[]): void {
      this.categories = categories
    },
    ADD_CATEGORY (payload: ICategory): void {
      this.categories.push(payload)
    },
    REMOVE_CATEGORY (payload: ICategory): void {
      const idx = this.categories.findIndex((el: any) => el.id === payload.id)
      this.categories.splice(idx, 1)
    },
    SET_ALERT (data: IAlert): void {
      this.alert = [data]
    },
    SET_TEMP_ALERT (data: IAlert): void {
      this.alert.push(data)
      setTimeout(() => {
        const index = this.alert.indexOf(data);
        if (index !== -1) {
          this.alert.splice(index, 1);
        }
      }, data.timeout || timeouts.closeToast)
    },
    CLEAR_ALERT (): void {
      this.alert = []
    },
    SET_SEND_MESSAGE_MODAL (data: boolean): void {
      this.sendMessageModal = data
    },
    SET_DISCONNECTED_TIME (data: number): void {
      this.disconnectedTime = data
    },
    SET_ADMIN_DATA_CHANGE (payload: IPushData): void {
      this.adminDataChange = payload
    },
    SET_USERS (payload: IUser[]) {
      this.users = payload
    },
    SET_ADMIN_CATEGORIES (payload: ICategory[]): void {
      this.adminCategories = payload
    },
    SET_OFFLINE_STATUS (status: boolean): void {
      this.isOffline = status
    },
    SET_LOGOUT_CLICKED (status: boolean): void {
      this.isLogoutClicked = status
    },
    SET_SOCKET_CONNECTION_STATUS (status: SocketConnectionStatus): void {
      this.socketConnectionStatus = status
    },
    SET_GLOBAL_THEME (theme: ThemeType): void {
      Vuetify.framework.theme.dark = theme === ThemeType.dark
      this.globalTheme = theme
    },
    SET_UNEXPECTED_ERROR (text: string): void {
      this.unexpectedError = text
    },
    SET_ADMIN_DRAWER (drawer: boolean): void {
      this.adminDrawer = drawer
    },
    SET_CLIENT_DATA (clientData: IClientList): void {
      this.clientList = clientData.clients; // was clientData before guest functionality
      this.guestList = clientData.guests;
      this.realtimeClientData = clientData.realtimeUsersData;
    },
    SET_SOCKET_DISCONNECT_STATUS (status: SocketDisconnectReason): void {
      this.socketDisconnectReason = status
    },
    ADD_API_LOADING_ENTRY(apiCall: string, isSocket: boolean): Date { //TODO perhaps use enum for apiCall
      const timestamp = new Date();
      this.apiLoading.push({
        apiCall,
        isSocket,
        timestamp
      });
      return timestamp;
    },
    LIST_CURRENT_API_CALLS(): void {
      for (const apiCallEntry of this.apiLoading) {
        console.log(`API call ${apiCallEntry.apiCall}, socket ${apiCallEntry.isSocket} started at ${apiCallEntry.timestamp}`);
      }
    },
    REMOVE_API_LOADING_ENTRY(apiCall: string, timestamp: any): void {
      const index = this.apiLoading.findIndex(e => {return e.apiCall === apiCall && e.timestamp === timestamp});
      if (index !== -1) {
        this.apiLoading.splice(index, 1);
      }
    },
    /**
     * Reset the app (disconnect from the socket if connected, reset the vuex to default state, redirect to the login page if not already there)
     */
    resetApp(): void {
      const auctionStore= useAuctionStore()
      console.log("resetApp action called")

      // Disconnect from the socket if connected
      if (this.socket) {
        if (this.socket.disconnect) { //needed for auctioneers screen and viewer screen)
          this.socket.disconnect()
        }
        if (this.socket.removeAllListeners) { //needed for auctioneers screen and viewer screen)
          this.socket.removeAllListeners()
        }
        SocketClient.removeInstance()
        console.log('socket disconnected after logout')
      } else {
        console.log('socket not disconnected as already closed from the backend')
      }

      // Update profile
      const localization = this.appLocalization
      this.UPDATE_GLOBAL_STATE({ key: 'userProfile', value: {} });
      auctionStore.$reset();
      this.$reset();
      this.UPDATE_GLOBAL_STATE({ key: 'appLocalization', value: localization })
      const cookies = localStorage.getItem('vue-cookie-accept-decline-cookies')
      localStorage.clear()
      if (cookies != null) {
        localStorage.setItem('vue-cookie-accept-decline-cookies', cookies)
      }
      Vue.$cookies.keys().forEach(cookie => Vue.$cookies.remove(cookie))
      if (localFeatures.useSentry) {
        Sentry.setUser(null);
      }
      // Back to login page
      // router.push({ name: 'login' })
      if (router.currentRoute.name !== 'login') router.push({ name: 'login' })
      this.getAppSettings()
    },
    /**
     * Log the user out (delete the jwt if it exists and is valid)
     */
    async logout (): Promise<void> {
      const auctionStore = useAuctionStore()
      console.log("logout action called")
      if (this.isLogoutClicked && !_.isEmpty(auctionStore.currentAuction)) {
        await auctionStore.leaveAuction(auctionStore.currentAuction.id, false);
      }
      const token = getAuthToken()
      if (token && checkIfTokenIsValid(token)) {
        dispatcher.logout().catch(e => {
          console.log('logout call failed, continuing')
        }).finally(() => {
          removeAuthToken()
          this.resetApp()
        })
      } else {
        removeAuthToken()
        this.resetApp()
      }

      /*
      const token = getAuthToken()
      if (token && checkIfTokenIsValid(token)) {
        apiRequests.logout().catch(e => {
          console.log('logout call failed, continuing')
        }).finally(() => {
          removeAuthToken()
        })
        dispatch('resetApp')
      } else {
        removeAuthToken()
        dispatch('resetApp')
      }
      */
    },
    /**
     * Run the kickout logic, this happens if the user gets kicked out (delete the jwt,disconnect from the socket if connected, reset the vuex to default state, redirect to the login page)
     */
    async kickOut (): Promise<void> {
      console.log("kickout action called")
      // Remove token
      removeAuthToken()

      this.resetApp()
    },
    /**
     * Get user profile from current user (updates the store)
     * @return {Promise<IUser>} - The user profile or null if empty
     */
    async getCurrentUserProfile (): Promise<IUser> {
      let result = await dispatcher.getUserProfile();
      this.UPDATE_GLOBAL_STATE({ key: 'userProfile', value: result});
      return result;
    },
    async getNotification (): Promise<void> {
      try {
        let result = await dispatcher.getNotification(getConnectedUser());
        this.UPDATE_GLOBAL_STATE({ key: 'notificationList', value: result });
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: i18n.t('There was an error loading the notifications. Please try again later') });
      }
    },
    async markReadNotification (id: number): Promise<void> {
      try {
        await dispatcher.markReadNotification(getConnectedUser(), id);
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: i18n.t('There was an error marking the notification as read. Please try again later') });
      }
    },
    /**
     * Update user profile (updates the store)
     * @return {boolean} - True if the profile has been updated, false if not due to errors
     */
    async updateUserProfile (data: IUser): Promise<void> {
      const result = await dispatcher.updateUserProfile(data);
      this.UPDATE_GLOBAL_STATE({ key: 'userProfile', value: result});
    },
    // Get user settings or null if there is an error (i.e. http 401)
    async getUserSettings (): Promise<any|null> {
      try {
        let result = await dispatcher.getUserSettings();

        // get all settings
        this.UPDATE_GLOBAL_STATE({ key: 'appSettings', value: result.general });
        // get app features
        this.UPDATE_GLOBAL_STATE({ key: 'appFeatures', value: result.features });
        // get app localization settings
        this.UPDATE_GLOBAL_STATE({ key: 'appLocalization', value: result.localization });
        // get app stream settings
        this.UPDATE_GLOBAL_STATE({ key: 'streamSettings', value: result.builtinStreaming });
        return result;
      } catch (e) {
        return null
      }
    },
    // Get public settings
    async getAppSettings (): Promise<any> {
      try {
        let result = await dispatcher.getAppSettings()

        // get all settings
        this.UPDATE_GLOBAL_STATE({ key: 'appSettings', value: result.general });
        // get app features
        this.UPDATE_GLOBAL_STATE({ key: 'appFeatures', value: result.features });
        // get app localization settings
        this.UPDATE_GLOBAL_STATE({ key: 'appLocalization', value: result.localization });
        return result;
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: i18n.t('There is a temporary error in the application. Please try again later') })
      }
    },
    // Get users for the admin frontend
    async fetchUsers (): Promise<void> {
      try {
        const timestampResponse = await dispatcher.getUsersLastUpdatedTimestamp()
        const timestampResponseTime = timestampResponse.updated_at;
        let doFetch = false;
        if (this.lastUpdatedUsers === null) {
          doFetch = true;
        } else {
          const responseUpdateMoment = moment(timestampResponseTime)
          const usersLastUpdatedMoment = moment(this.lastUpdatedUsers)
          if (responseUpdateMoment.diff(usersLastUpdatedMoment, 'seconds') > 0) {
            doFetch = true;
          }
        }

        if (doFetch) {
          console.log('doing full users fetch')
          const result = await dispatcher.getUsers()
          this.SET_USERS(result);
          this.lastUpdatedUsers = timestampResponseTime;
        } else {
          console.log('users unchanged, skipping fetch')
        }
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: i18n.t('There was an error loading the user data. Please try again later') });
      }
    },
    // Get categories for the admin frontend
    async fetchAdminCategories (): Promise<void> {
      try {
        const timestampResponse = await dispatcher.getCategories(true);
        const timestampResponseTime = timestampResponse.updated_at;
        let doFetch = false;
        if (this.lastUpdatedAdminCategories === null) {
          doFetch = true;
        } else {
          const responseUpdateMoment = moment(timestampResponseTime)
          const adminCategoriesLastUpdatedMoment = moment(this.lastUpdatedAdminCategories)
          if (responseUpdateMoment.diff(adminCategoriesLastUpdatedMoment, 'seconds') > 0) {
            doFetch = true;
          }
        }

        if (doFetch) {
          console.log('doing full admin categories fetch')
          const result = await dispatcher.getCategories(false)
          this.SET_ADMIN_CATEGORIES(result);
          this.lastUpdatedAdminCategories = timestampResponseTime;
        } else {
          console.log('admin categories unchanged, skipping fetch')
        }
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: i18n.t('There was an error loading the data. Please try again later') });
      }
    },
    /**
     * Get all categories and update the store
     */
    async fetchCategories (): Promise<ICategory[]> {
      let categoriesData;
      if (this.isAuthenticatedAsUser) {
        categoriesData = await dispatcher.getCategories(false);
      } else {
        categoriesData = await dispatcher.getCategoriesGuest(false);
      }
      this.SET_CATEGORIES(categoriesData)
      return categoriesData;
    },
    SET_SOCKET_DATA (data: Record<string, any>): void {
      this.socket = data
    },
    async setBrowserTabId (): Promise<void> {
      const browserTabIdCoordinator = new TabIdCoordinator();
      this.UPDATE_GLOBAL_STATE({key: 'browserTabId', value: browserTabIdCoordinator.tabId});
    },
    async setBrowserFingerprint (): Promise<void> {
      const fingerprint = await getBrowserFingerprint();
      if (fingerprint) {
        this.UPDATE_GLOBAL_STATE({key: 'browserFingerprint', value: fingerprint.toString()});
        httpClient.setHeaderBrowserFingerprint(fingerprint.toString())
        console.log(`Browser fingerprint is ${fingerprint.toString()}`)
      }
    }
  }
})

export default useRootStore
