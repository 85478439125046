export const customerFeatures = {
  //put your customer specific features here

  // If enabled, a message will be displayed on the dashboard, product catalogue, highlights catalogue and port auction sale catalogue
  // in guest mode to login for full features
  //guestLoginPrompt: true,

    // If enabled, the language switcher will be shown
  //enableThemeSwitcher: false,
    // if enabled and when running in an iframe, a new tab will be opened when clicking on the bid button in the guest view or on the login button in the bottom modal as guest
  iFrameOpenLoginInNewTab: false
  }
