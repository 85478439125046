<template>
  <div id="app-user-auction-container" class="auction-container pt-0">
    <div class="user-profile-modal">
      <v-card class="user-profile-container user-catalogue">
        <div class="close-btn-wrapper">
          <v-btn class="close-btn" @click="close">
            <v-icon class="close-modal-icon">fa-times</v-icon>
          </v-btn>
        </div>
        <v-card-text class="main-card">
          <v-container>
            <div>
              <v-row align="center" class="form-container justify-center">
                <v-col sm="12" class="full-width">
                  <h2 class="page-title text-center">{{ $t('Highlights for auction') }}</h2>
                  <!--                  <v-col class="main-col-wrapper" v-if="!auctionWishList">-->
                  <!--                    <v-col align="center" class="sub-col-wrapper">-->
                  <!--                      <v-row>-->
                  <!--                        <search-box-->
                  <!--                          v-if="auctions && auctions.length > 0"-->
                  <!--                          :currentAuction="currentAuction"-->
                  <!--                          :auctions="auctions.filter(el => el.catalogue_enabled)"-->
                  <!--                          hide-create-new-->
                  <!--                          title=""-->
                  <!--                          @onSelectAuction="selectAuction"-->
                  <!--                        />-->
                  <!--                      </v-row>-->
                  <!--                    </v-col>-->
                  <!--                  </v-col>-->
                </v-col>
                <div class="scroll-anchor" ref="anchor" />
                <v-progress-circular v-if="auctionLoading" class="loading-icon mt-6" indeterminate />
                <template v-else>
                  <v-alert
                    v-if="!isAuthenticatedAsUser && visibleAlert && features.guestLoginPrompt"
                    border="left"
                    dense
                    outlined
                    text
                    type="info"
                    dismissible
                  >
                    {{ $t('Please note') }},
                    <span v-if="appFeatures.liveAuctionFunctionality">{{ $t('login_reminder_live') }}</span>
                    <span v-else>{{ $t('login_reminder_no_live') }}</span>
                    <br>
                    <a @click="openRegistration">
                      <strong>{{ $t('Register') }}</strong>
                    </a>
                    / <a @click="openLogin">
                      <strong>{{ $t('Login now') }}</strong>
                    </a>
                  </v-alert>
                  <h3 class="auction-title mb-4" v-if="selectedAuctionData && !_.isEmpty(highlights)">{{ auctionName(selectedAuctionData) }}</h3>
                  <auction-overview
                    v-if="selectedAuctionData"
                    :auction="selectedAuctionData"
                  />
                  <v-col sm="12" v-if="!_.isEmpty(highlights)">
                    <v-text-field
                      v-model="search"
                      append-icon="fa-magnifying-glass"
                      :label="$t('Search')"
                      hide-details
                      class="mb-3"
                      @input="searchHandler"
                      clearable
                    />
                    <div class="catalogue list-view">
                      <div class="catalogue__list">
                        <div
                          class="catalogue__item"
                          v-for="(article, i) in articlesView"
                          :key="article.id"
                        >
                          <div class="catalogue__item-header">
                            <v-tooltip top allow-overflow>
                              <template #activator="{ on, attrs }">
                                <svg
                                  v-bind="attrs"
                                  v-on="on"
                                  height="30px"
                                  width="30px"
                                  :fill="globalTheme === ThemeType.dark ? '#ffffff' : '#000000'"
                                  xmlns="http://www.w3.org/2000/svg"
                                  data-name="Layer 101"
                                  viewBox="0 0 100 100" x="0px" y="0px"
                                  class="catalogue__item-shipping icon-with-hover mr-2"
                                  v-if="article.no_shipping"
                                >
                                  <path d="M50,90A40,40,0,1,0,10,50,40,40,0,0,0,50,90Zm0-73.23a33.1,33.1,0,0,1,21,7.46l-4.39,4.39a.63.63,0,0,1,.16.26L69,35.76,75.77,29A33.23,33.23,0,0,1,29,75.77l4.13-4.13h-2.4a.76.76,0,0,1-.75-.75V65.21L24.23,71A33.23,33.23,0,0,1,50,16.77Z" /><path d="M68.5,70.14V39.56H54v8.87a.76.76,0,0,1-.75.75H46.79a.76.76,0,0,1-.75-.75V39.56H31.5V70.14Z" /><polygon points="31.79 38.05 46.08 38.05 46.53 29.86 34.5 29.86 31.79 38.05" /><polygon points="53.92 38.05 68.21 38.05 65.5 29.86 53.47 29.86 53.92 38.05" /><polygon points="52.42 38.05 51.96 29.86 48.04 29.86 47.58 38.05 52.42 38.05" /><rect x="47.54" y="39.56" width="4.92" height="8.13" />
                                </svg>
                              </template>
                              <span>{{ $t('No shipping possible') }}</span>
                            </v-tooltip>
                            <v-tooltip top allow-overflow v-if="enableArticleInqirires">
                              <template #activator="{ on, attrs }">
                                <div
                                  v-bind="attrs"
                                  v-on="on"
                                  class="catalogue__item-inquiry icon-with-hover mr-2 input-field-bigger-icon"
                                  @click="openInquiryModal(article)"
                                  v-if="isAuthenticatedAsUser"
                                >
                                  <v-icon>fa-question-circle</v-icon>
                                </div>
                              </template>
                              <span>{{ $t('Lot inquiry') }}</span>
                            </v-tooltip>
                            <v-tooltip top allow-overflow>
                              <template #activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  icon
                                  class="catalogue__item-wish icon-with-hover"
                                  :class="{ active: article.wishlist }"
                                  @click="addToWishList(article)"
                                  :disabled="wishlistError"
                                  v-if="isAuthenticatedAsUser"
                                >
                                  <v-icon :key="tableKey" :style="{ fontWeight: article.wishlist ? 900 : 400 }">fa-heart</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t('My Wishlist') }}</span>
                            </v-tooltip>
                          </div>
                          <div class="catalogue__item-body">
                            <div class="catalogue__show-image" v-show="article.offensive && !showImages[`article${article.id}`]">
                              <div class="info-text" @click.stop="showImageToggle(article.id)" v-if="isAuthenticatedAsUser">
                                <v-icon>fa-info-circle</v-icon>
                                <p>
                                  {{ $t('offensive_article_button_show_image') }}<br>
                                  <a :href="appSettings.url_rules" target="_blank">{{ $t('offensive_article_button_show_image2') }}</a>
                                </p>
                              </div>
                              <v-btn class="btn old-styling-btn" @click.stop="openLogin" v-else>{{ $t('Login to see images') }}</v-btn>
                            </div>
                            <div
                              class="catalogue__image"
                              ref="carousel"
                              v-if="article.images && article.images.length && article.images.length <= 1"
                              v-show="showImages[`article${article.id}`] || !article.offensive"
                              @click="e => selectImage(e, article.images, 0)"
                            >
                              <v-img contain :src="article.images[0].image" :style="{'max-height': isMobile ? '114px' : '156px'}"/>
                            </div>
                            <div class="catalogue__slider" v-else-if="article.images && article.images.length" v-show="showImages[`article${article.id}`] || !article.offensive">
                              <div class="custom-arrow slick-arrow slick-next" @click.stop="$refs.carousel[i].next()">
                                <v-icon class="arrow-left">fa-angle-right</v-icon>
                              </div>
                              <div class="custom-arrow slick-arrow slick-prev" @click.stop="$refs.carousel[i].prev()">
                                <v-icon class="arrow-right">fa-angle-left</v-icon>
                              </div>
                              <VueSlickCarousel ref="carousel" lazyLoad="ondemand" :arrows="false" :key="article.images.length" :dots="false">
                                <template #prevArrow>
                                  <div class="custom-arrow">
                                    <v-icon class="arrow-left">fa-angle-left</v-icon>
                                  </div>
                                </template>
                                <template #nextArrow>
                                  <div class="custom-arrow">
                                    <v-icon class="arrow-right">fa-angle-right</v-icon>
                                  </div>
                                </template>
                                <div
                                  class="image-item"
                                  v-for="(itm, idx) in article.images"
                                  :key="itm.articleNumber"
                                  @click.stop="e => selectImage(e, article.images, idx)"
                                  @keydown.left.stop=""
                                  @keydown.right.stop=""
                                >
                                  <v-img contain class="article-image" :src="itm.image" :style="{'max-height': isMobile ? '114px' : '156px'}"></v-img>
                                </div>
                              </VueSlickCarousel>
                            </div>
                            <div class="catalogue__item-content" :style="{ width: article.images && article.images.length ? '' : '100%' }">
                              <h3 class="catalogue__name">{{ articleTitle(article) }}</h3>
                              <v-row class="my-3 px-3" justify="space-between" align="center">
                                <div class="catalogue__meta mt-0">
                                  <div class="catalogue__meta-item">
                                    <p class="catalogue__meta-name">{{ $t('Article') }}</p>
                                    <h5 class="catalogue__meta-value">#{{ article.number_optional || article.number }}</h5>
                                  </div>
                                  <div class="catalogue__meta-item" v-if="article.limit">
                                    <p class="catalogue__meta-name">{{ $t('Limit') }}</p>
                                    <h5 class="catalogue__meta-value">{{ currency }} {{ euNumbers(article.limit) }}</h5>
                                  </div>
                                  <div class="catalogue__meta-item" v-if="article.estimated_price">
                                    <p class="catalogue__meta-name">{{ $t('Estimated price') }}</p>
                                    <h5 class="catalogue__meta-value">{{ currency }} {{ article.estimated_price }}</h5>
                                  </div>
                                </div>
                              </v-row>
                              <p v-if="useHtml" class="catalogue__description" v-html="articleDescription(article)"></p>
                              <p v-else class="catalogue__description">{{ articleDescription(article) }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col class="text-center" v-if="!selectedAuctionData && getAuctionError">
                    <div class="status__item status__item--error">
                      <div class="status__icon" />
                      <p class="status__text">{{ $t(`This auction doesn't exist`) }}</p>
                    </div>
                  </v-col>
                </template>
                <div class="info-text" v-if="selectedAuctionData && !auctionLoading && _.isEmpty(highlights)">
                  <v-icon>fa-info-circle</v-icon>
                  <p>{{ $t('No articles are found') }}</p>
                </div>
              </v-row>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
      <div class="pagination-container" v-if="totalItems">
        <v-row class="ma-0" justify="center" align="center">
          <v-col>
            <v-row class="ma-0 d-flex" align="center" :justify="isMobile ? 'space-around' : 'start'">
              <v-menu offset-y close-on-content-click>
                <template #activator="{ on, attrs }">
                  <v-btn
                    text
                    v-bind="attrs"
                    v-on="on"
                    class="dropdown-btn customizable"
                  >
                    <span class="highlight">{{ countPerPageDefault }}<v-icon class="ml-1">fa-angle-down</v-icon></span>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item
                    v-for="(item, index) in itemsPerPage"
                    :key="index"
                    @click="changePerPage(item)"
                  >
                    <v-list-item-title class="text-right">{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <div class="ml-2 mr-2 pagination-pages" v-if="!isMobile">
                {{ page * countPerPageDefault + 1 }}-{{ totalPages > 1 ? totalPages === page + 1 ? totalItems : (page + 1) * countPerPageDefault : totalItems }} {{ $t('of') }} {{ totalItems }} {{ $t('items') }}
              </div>
              <div class="wish-list-separator" />
              <v-btn
                @click="openWishList"
                class="btn old-styling-btn ml-0 wrap-text"
                :disabled="wishlistError"
                v-if="isAuthenticatedAsUser"
                style="max-width: 45%"
              >
                <v-icon class="mr-2">fa-heart</v-icon>
                {{ $t('View Wishlist') }}
              </v-btn>
              <v-btn
                @click="openBidsView"
                class="btn old-styling-btn ml-2 wrap-text"
                v-if="isAuthenticatedAsUser && selectedAuctionData.type === AuctionFlavor.live && moment(selectedAuctionData.active_until).diff(moment(), 'seconds') > 0 && selectedAuctionData.catalogue_enabled !== 'disabled' && selectedAuctionData.commission_bids_enabled"
                style="max-width: 45%"
              >
                <v-icon class="mr-2" style="font-weight: 900">fa-gavel</v-icon>
                {{ $t('View absentee bids') }}
              </v-btn>
              <v-btn
                @click="openBidsView"
                v-else-if="isAuthenticatedAsUser && selectedAuctionData.type === AuctionFlavor.timed && moment(selectedAuctionData.active_until).diff(moment(), 'seconds') > 0 && selectedAuctionData.catalogue_enabled !== 'disabled' && selectedAuctionData.status !== AuctionStatusType.completed && selectedAuctionData.status !== AuctionStatusType.stopped"
                class="btn old-styling-btn ml-2 wrap-text"
                :disabled="commissionError"
                style="max-width: 45%"
              >
                <v-icon class="mr-2" style="font-weight: 900">fa-gavel</v-icon>
                {{ $t('View bids') }}
              </v-btn>
            </v-row>
          </v-col>
          <custom-pagination v-if="totalPages > 1" :page="page + 1" @changePage="changePage" :length="totalPages" :totalVisible="10" />
        </v-row>
      </div>
      <CoolLightBox
        :items="selectedImages"
        :index="lightBoxIndex"
        :useZoomBar="true"
        :disableZoom=disableZoomOnMobile
        :closeOnClickOutsideMobile="true"
        @close="lightBoxIndex = null"
      />
    </div>
    <user-article-inquiry-modal
      ref="inquiryModal"
      :article="selectedArticle"
      @clearSelectedArticle="selectedArticle = {}"
    />
    <registration ref="registrationModal" />
  </div>
</template>
<script>
import _ from 'lodash'
import { mapActions, mapState } from 'pinia'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import UserArticleInquiryModal from '@/components/modals/user-article-inquiry-modal'
import Registration from '@/components/modals/guest-registration-modal'
import { localFeatures } from '@/utils/constants';
import CustomPagination from '@/components/blocks/custom-pagination.vue'
import dispatcher from '@/api/dispatch';
import moment from 'moment';
import useRootStore from '@/stores/rootStore';
import { createArticleSearchIndex, searchArticles } from '@/services/article';
import { euNumbers, getFormattedDate, getTranslatedText } from '@/services/i18n';
import AuctionOverview from '@/components/blocks/auction-overview.vue';
import { getImageFullUrl, getImageOrFallback } from '@/services/auction';
import { AlertFlavor, AuctionFlavor, AuctionStatusType, ThemeType } from '@/api/enums';
import useAuctionStore from '@/stores/auctionStore';
import { getLinkTarget } from '@/utils';

export default {
  name: 'HighlightsView',
  components: {
    AuctionOverview,
    VueSlickCarousel,
    CoolLightBox,
    UserArticleInquiryModal,
    Registration,
    CustomPagination
  },
  data () {
    return {
      renderComponent: true,
      search: '',
      countPerPageDefault: localFeatures.elementsPerPageDefault,
      itemsPerPage: localFeatures.elementsPerPage,
      page: 0,
      start: 0,
      end: 0,
      key: 0,
      error: '',
      loading: false,
      searchResult: [],
      tmpArticles: [],
      articlesView: [],
      highlightsArticles: [],
      selectedImages: [],
      lightBoxIndex: null,
      auctions: null,
      selectedAuctionData: null,
      articles: null,
      highlights: null,
      auctionLoading: false,
      selectedArticle: {},
      wishlistError: false,
      tableKey: 0,
      getAuctionError: false,
      showImages: {},
      visibleAlert: true,
      features: localFeatures,
      useHtml: localFeatures.useHtml,
      searchKeys: ['name', 'description'], // number and number_optional will always be searched
      articleSearchIndex: null,
      enableArticleInqirires: localFeatures.enableArticleInqirires
    }
  },
  computed: {
    ThemeType() {
      return ThemeType
    },
    AuctionStatusType() {
      return AuctionStatusType
    },
    AuctionFlavor() {
      return AuctionFlavor
    },
    ...mapState(useRootStore, ['appLocalization', 'appSettings', 'appStoreLang', 'userProfile', 'isMobile', 'appFeatures', 'isAuthenticatedAsUser', 'globalTheme']),
    ...mapState(useAuctionStore, ['auctionsFetchedArticles']),
    totalPages() {
      return Math.ceil(this.totalItems / this.countPerPageDefault)
    },
    emptyFields() {
      return false
    },
    disabledSave() {
      return true
    },
    headers() {
      return [
        {
          text: this.$t('Article #'),
          value: 'number',
        },
        {
          text: this.$t('Article name'),
          value: 'name',
        },
        {
          text: this.$t('Description'),
          value: 'description',
        }
      ]
    },
    totalItems () {
      return this.tmpArticles.length
    },
    currency () {
      return this.appLocalization.currency
    },
    selectedAuctionDate() {
      const d = new Date(this.selectedAuctionData.start_at);
      const ye = d.getFullYear()
      const mo = d.toLocaleString(this.appStoreLang, { month: 'long', timeZone: 'UTC' });
      const da = d.getDate()
      const h = d.getHours()
      const m = d.getMinutes()
      const dayPrefix = da === 1 ? 'st' : da === 2 ? 'nd' : da === 3 ? 'rd' : 'th'
      return this.appStoreLang === 'en' ? `${mo} ${da}${dayPrefix}, ${ye} ${h}:${m <= 9 ? '0' + m : m}` : `${da}. ${mo}, ${ye} ${h}:${m <= 9 ? '0' + m : m}`
    },
    disableZoomOnMobile() {
      return !!this.isMobile
    }
  },
  async created() {
    await this.getAuctions()
    this.selectAuction(this.$route.params.id)
    this.articleSearchIndex = createArticleSearchIndex(this.tmpArticles); // is always empty because article data is loaded afterwards, watcher needed to update index
  },
  methods: {
    ...mapActions(useRootStore, ['SET_TEMP_ALERT']),
    ...mapActions(useAuctionStore, ['getArticles']),
    getFormattedDate,
    getFieldName(obj, field) {
      let langTarget
      if (typeof obj[`${field}_i18n`] === 'string' && obj[`${field}_i18n`].length > 0) {
        langTarget = JSON.parse(obj[`${field}_i18n`])
      } else {
        langTarget = obj[`${field}_i18n`]
      }
      if (langTarget && Object.keys(langTarget).length > 0 && langTarget[this.appStoreLang]) {
        return `${field}_i18n`
      }
      return field
    },
    euNumbers,
    changePage (el) {
      // this.page = el
      this.page = el - 1
      this.setPage();
      this.$refs.anchor.scrollIntoView({ behavior: 'smooth' })
      this.getArticlesData()
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;
      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    async addToWishList(article) {
      if (article.wishlist) {
        try {
          await dispatcher.removeFromWishlist(this.selectedAuctionData.id, this.userProfile.id, article.id)
          this.SET_TEMP_ALERT({ flavor: AlertFlavor.success, content: this.$t('Article has been removed from your wishlist'), timeout: 2000 })
          article.wishlist = false
        } catch (e) {
          this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('There was an error removing that article from the wishlist. Please try again later.') })
        }
      } else {
        try {
          await dispatcher.addToWishlist(this.selectedAuctionData.id, this.userProfile.id, article.id)
          this.SET_TEMP_ALERT({ flavor: AlertFlavor.success, content: this.$t('Article has been placed on your wishlist'), timeout: 2000 })
          article.wishlist = true
        } catch (e) {
          this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('There was an error adding that article to the wishlist. Please try again later.') })
        }
      }
      this.tableKey++
    },

    async getAuctionDetail(id) {
      try {
        this.auctionLoading = true
        await this.getArticles(id)
        this.highlights = this.auctionsFetchedArticles[`auction_${id}`].filter(el => el.is_highlight)

        const sorted = [...this.highlights.sort((a, b) => {
          if (a.number && b.number) {
            return a.number - b.number
          } else {
            return a.number_optional - b.number_optional
          }
        })]

        if (this.isAuthenticatedAsUser) {
          try {
            const wishListResp = await dispatcher.getWishlist(id, this.userProfile.id)
            this.wishlistError = false
            this.wishListData = wishListResp

            wishListResp.forEach(el => {
              sorted.forEach(article => {
                if (article.id === el.articles_id) {
                  article.wishlist = true
                  article.wishlistId = el.id
                }
              })
            })
          } catch (e) {
            this.wishlistError = true
            this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('The wishlist is currently unavailable due to a technical error. Please try again later.') })
            throw e;
          }
        }

        this.highlightsArticles = sorted
        this.tmpArticles = sorted
        this.error = null
        if (this.$route.query.page) {
          if (this.$route.query.page <= this.totalPages) {
            this.changePage(this.$route.query.page);
          } else {
            this.setPage();
          }
        }
        this.getArticlesData()
        this.auctionLoading = false
      } catch (e) {
        this.getAuctionError = true
        this.auctionLoading = false
        this.loading = false
      }
    },

    getArticlesData() {
      this.articlesView = this.tmpArticles.slice(this.page * this.countPerPageDefault, (this.page + 1) * this.countPerPageDefault)
    },
    searchArticles,
    searchHandler: _.debounce(function () {
      this.page = 0
      if (this.search) {
        this.tmpArticles = this.searchArticles(this.articleSearchIndex, this.search, this.highlightsArticles, this.searchKeys, this.appStoreLang)
      } else {
        this.tmpArticles = [...this.highlightsArticles]
      }
      this.getArticlesData()
    }, 500),
    articleTitle (article) {
      return getTranslatedText(article, 'name', this.appStoreLang)
    },
    articleDescription (article) {
      return getTranslatedText(article, 'description', this.appStoreLang)
    },
    auctionName (auction) {
      return getTranslatedText(auction, 'name', this.appStoreLang)
    },
    setPage() {
      if (+this.$route.query.page !== (this.page + 1)) this.$router.replace({ query: { ...this.$route.query, page: this.page + 1 } })
    },
    changePerPage(item) {
      this.countPerPageDefault = item
      this.page = 0
      this.setPage()
      this.getArticlesData()
    },

    close () {
      this.$router.push({ name: 'dashboard' })
    },
    async selectImage(e, images, index) {
      if (e.target.classList.contains('image-item')) {
        e.target.blur()
      } else {
        e.target.parentNode.blur()
      }
      let arr = []
      for (let image of images) {
        const res = await getImageOrFallback(getImageFullUrl(image.image, this.selectedAuctionData.code), image.image)
        arr.push(res)
      }
      this.selectedImages = arr
      this.lightBoxIndex = index
    },

    async getAuctions() {
      try {
        let data
        if (this.isAuthenticatedAsUser) {
          data = await dispatcher.getAllAuctions();
        } else {
          data = await dispatcher.getGuestAuctions()
        }
        this.auctions = data.filter(el => el.catalogue_enabled !== 'disabled' && moment().diff(el.active_until, 'seconds') < 0)
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('There was an error getting the auctions. Please try again later') });
        this.error = true;
      }
    },
    selectAuction(id) {
      this.selectedAuctionData = this.auctions.find(el => el.code.toString() === id.toString())
      if (this.selectedAuctionData) {
        this.getAuctionDetail(this.selectedAuctionData.id)
      } else {
        this.getAuctionError = true
      }
    },
    openInquiryModal(article) {
      this.selectedArticle = article
      this.$refs.inquiryModal.open()
    },
    openWishList() {
      this.$router.push({ name: 'wishlist', params: { id: this.$route.params.id } })
    },
    openBidsView() {
      this.$router.push({ name: 'bids', params: { id: this.selectedAuctionData.code } })
    },
    showImageToggle(id) {
      this.showImages = {
        ...this.showImages,
        [`article${id}`]: true
      }
    },
    openLogin() {
      const routeData = this.$router.resolve({ name: 'login', query: { nextUrl: this.$route.fullPath.trim() } })
      window.open(routeData.href, getLinkTarget());
    },
    openRegistration() {
      this.visibleAlert = false;
      this.$refs.registrationModal.openModal()
    }
  },
  watch: {
    auctionsFetchedArticles: {
      deep: true,
      handler(val) {
        if (!this.selectedAuctionData || !this.tmpArticles || !this.wishList) return false
        let articles = val[`auction_${this.selectedAuctionData.id}`]
        if (!articles) return

        const updatedArticles = articles.map(el => {
          const prevVal = this.tmpArticles.find(article => article.id === el.id)
          return {
            ...prevVal,
            ...el
          }
        })

        this.highlights = updatedArticles.filter(el => el.is_highlight)

        const sorted = [...this.highlights.sort((a, b) => {
          if (a.number && b.number) {
            return a.number - b.number
          } else {
            return a.number_optional - b.number_optional
          }
        })]

        this.wishListData.forEach(el => {
          sorted.forEach(article => {
            if (article.id === el.articles_id) {
              article.wishlist = true
              article.wishlistId = el.id
            }
          })
        })

        this.tmpArticles = sorted

        this.key++
        this.getArticlesData()
      }
    },
    highlights: {
      deep: true,
      handler(val) {
        this.articleSearchIndex = createArticleSearchIndex(val);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.catalogue {
  .v-dialog {
    .dropdown-btn {
      color: #000 !important;
    }
  }
  @media (max-width: 968px) {
    .catalogue__image,
    .catalogue__slider,
    .catalogue__show-image {
      margin-right: 25px;
    }
  }
}
</style>
