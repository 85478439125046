import { Role } from '@/api/enums';

export const defaultRoute = {
  [Role.admin]: '/auction-admin',
  [Role.user]: '/dashboard',
  [Role.viewer]: '/auction-viewer',
  [Role.auctioneer]: '/auctioneers-screen',
  [Role.streamer]: '/streaming',
  [Role.guest]: '/login'
}

export const timeouts = {
  closeToast: 3000,
  closeModal: 2000,
  debounce: 300,
  closeSweetalert: 5000
}
export const colors = {
  count_articles: ['#77CEFF', '#123E6B', '#0079AF'],
  count_registrations: ['#77CEFF', '#123E6B', '#0079AF', '#97B0C4'],
  count_commission_bids: ['#77CEFF', '#123E6B', '#0079AF', '#97B0C4'],
  count_knockdowns: ['#77CEFF', '#123E6B', '#0079AF', '#97B0C4', '#A5C8ED'],
  turnover: ['#77CEFF', '#123E6B', '#0079AF', '#97B0C4', '#A5C8ED']
}

export const statusNames = {
  0: {
    value: 'sold',
    text: 'articleStatus.sold'
  },
  1: {
    value: 'available',
    text: 'articleStatus.available'
  },
  2: {
    value: 'on auction',
    text: 'articleStatus.onAuction'
  },
  3: {
    value: 'skipped',
    text: 'articleStatus.skipped'
  },
  4: {
    value: 'passed',
    text: 'articleStatus.passed'
  }
}

export const commissionBidStatusNames = {
  'pending': {
    text: 'commissionBidStatus.pending'
  },
  'accepted': {
    text: 'commissionBidStatus.accepted'
  },
  'declined': {
    text: 'commissionBidStatus.declined'
  }
}

export const knockdownStatusNames = {
  'live': {
    text: 'knockdownStatus.live'
  },
  'room': {
    text: 'knockdownStatus.room'
  },
  'timed': {
    text: 'knockdownStatus.timed'
  },
  'post-auction': {
    text: 'knockdownStatus.postAuction'
  },
  'commission': {
    text: 'knockdownStatus.commission'
  },
  'instant-buy': {
    text: 'knockdownStatus.instantBuy'
  }
}

export const SUCCESS_REQUEST = 200;
export const BAD_REQUEST_ERROR = 400;
export const UNAUTHORIZED_ERROR = 401;
export const ENTITY_DUPLICATION_ERROR = 403;
export const NOT_FOUND_ERROR = 404;
export const INTERNAL_SERVER_ERROR = 500;

// frontend features
export let localFeatures = {
  // If enabled, a message will be displayed on the dashboard, product catalogue, highlights catalogue and port auction sale catalogue
  // in guest mode to login for full features
  guestLoginPrompt: true,
  // If enabled, the search field will be visible in guest mode
  guestSearchField: true,
  // If enabled, if the user is logged in is not yet signed up for the auction, display a message on the product catalogue with a link to sign up
  // It will only show if signup_enabled is set for that auction and if the status is not "completed"
  userAuctionSignupReminder: true,
  // If enabled, the input for the message on a post auction sale request will not be shown
  disablePostAuctionInquiryMessage: false,
  // If enabled, the custom sort order will be used for categories. If not, categories will be sorted alphabetically
  categoriesFixedSortOrder: true,
  // specify the sort order by using category ids. If empty or null, the sorting will be done alphabetically
  categoryFixedSortOrderValues: [],
  // If enabled, the language switcher will be shown
  enableThemeSwitcher: true,
  // if this is set to >= 3, there will be a fixed amount of article per row on the grid view regardless of the screen size
  // otherwise, there will by a dynamic count of articles per row depending on the screen size
  gridViewItemsPerRow: 0,
  // If this is set to true, the grid view will be the default view on the product catalogue and post auction sale catalogue instead of the list view
  gridViewActiveByDefault: false,
  // custom auctionhouse banner for the header
  urlBannerAuctionhouse: '',
  // number of elements per page (i.e. product cataloge) - only for desktop view
  elementsPerPage: [50, 100, 150, 200],
  // default for elements per page
  elementsPerPageDefault: 50,
  // show the logo on the left on the desktop view
  showLogoOnDesktop: true,
  // show the logo on the left on the desktop view
  showLogoOnMobile: true,
  // show the auctionhouse banner (if enabled) on the mobile view
  mobileViewShowBanner: false,
  // use html in data (article description)
  useHtml: false,
  // show the auction number in the auction search component
  showAuctionNumberOnSearchField: true,
  // show the next auction on top in the dasboard, if there are more then 1 auction (sorts by start_at ascending)
  dashboardNewestAuctionOnTop: false,
  // show the total commission costs per auction
  showTotalCommissionCosts: true,
  // enable article inquiries
  enableArticleInqirires: true,
  // If this is set to true, errors are shown if run in development mode
  showErrorsOnDevelopment: false,
  // If enabled, Sentry.js will be used for error reporting
  useSentry: false,
  // If enabled, errors and warning will be sent via the backend
  useTelemetry: false,
  // if enabled and when running in an iframe, a new tab will be opened when clicking on the bid button in the guest view or on the login button in the bottom modal as guest
  iFrameOpenLoginInNewTab: false
}

export const searchableArticleKeys = [
  "name",
  "description",
  "number",
  "number_optional",
  "buy_price",
  "limit",
  "offensive",
  "no_shipping",
  "is_highlight"
]

export const translateableArticleKeys = [
  "name",
  "description"
]
