<template>
  <v-dialog max-width="550" v-model="dialog" persistent>
    <v-card-title class="headline">{{ title }}</v-card-title>
    <v-card-text v-if="text">{{ text }}</v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn class="text-none" @click="$emit('cancel')" :disabled="loading">{{ $t('No') }}</v-btn>
      <v-btn class="text-none" color="success" @click="$emit('submit')" :loading="loading">{{ $t('Yes') }}</v-btn>
    </v-card-actions>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmationModal',
  props: {
    title: {},
    text: {},
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    openModal() {
      this.dialog = true
    },
    closeModal() {
      this.dialog = false
    }
  }
}
</script>

<style scoped lang="scss">
.btn {
  width: 140px;
}
</style>
